import React, { useState, useCallback } from 'react'
import { Banner, Button, Card, Layout } from '@shopify/polaris'
import DropZoneComponents from '../components/DropZone'
import ExportModal from '../components/ExportModal'
import { authInstance } from '../helper'

const Page1 = () => {
  const [active, setActive] = useState(false)
  const [crmFile, setCrmFile] = useState()
  const [itemFile, setItemFile] = useState()
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)

  const handleChange = useCallback(() => setActive(!active), [active])

  const uploadToBucket = async () => {
    await authInstance.get('/reports/upload-to-orca')
    setOpenSuccessBanner(true)
  }

  const uploadFiles = async () => {
    console.log('upload file')
    const formData = new FormData()
    formData.append('crmFile', crmFile)
    formData.append('itemFile', itemFile)
    await authInstance.post('/reports/upload-to-shopify', formData)
    setOpenSuccessBanner(true)
  }

  return (
    <div>
      <ExportModal
        active={active}
        setActive={setActive}
        handleChange={handleChange}
        setOpenSuccessBanner={setOpenSuccessBanner}
      />
      <Card.Section>
        <div className='mb-4'>
          {openSuccessBanner && <Banner
            title='工作項目已加入排程'
            status='success'
            onDismiss={() => {
              setOpenSuccessBanner(false)
            }}
          />}
        </div>
        <div className='flex justify-end'>
          <div className='mr-2'>
            <Button monochrome outline onClick={uploadToBucket}>同步至ORCA</Button>
          </div>
          <div className='mr-2'>
            <Button monochrome outline onClick={handleChange}>匯出表單</Button>
          </div>
        </div>
      </Card.Section>
      <Card.Section>
        <Layout>
          <Layout.Section oneThird>
            <DropZoneComponents title='CRM Profile' file={crmFile} setFile={setCrmFile} />
          </Layout.Section>
          <Layout.Section oneThird>
            <DropZoneComponents title='Items'file={itemFile} setFile={setItemFile} />
          </Layout.Section>
          <Layout.Section>
            <div className='flex justify-end'>
              <Button onClick={uploadFiles}>上傳</Button>
            </div>
          </Layout.Section>
        </Layout>
      </Card.Section>
    </div>
  )
}

export default Page1
