import React, { useState, useCallback, useEffect } from 'react'
import { Card, FooterHelp, Link, Page, Tabs } from '@shopify/polaris'
import { ExternalMinor } from '@shopify/polaris-icons'
import { authInstance } from '../helper'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'

export default function Main () {
  const [selected, setSelected] = useState(0)
  const [metabaseUrl, setMetabaseUrl] = useState('')

  const getMetabaseUrl = async () => {
    const res = await authInstance.get('/me', {
      params: {
        fields: 'metabaseUrl'
      }
    })
    if (res.data.metabaseUrl) setMetabaseUrl(res.data.metabaseUrl)
  }

  useEffect(() => {
    getMetabaseUrl()
  }, [])

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  )

  const tabs = [
    {
      id: 'all-customers-1',
      content: '匯入/匯出表單'
    },
    {
      id: 'accepts-marketing-1',
      content: '紀錄'
    },
    {
      id: 'repeat-customers-1',
      content: 'LINE綁定 / 社群登入'
    }
  ]

  return (
    <Page
      secondaryActions={[{
        content: 'ORCA Dashboard',
        external: true,
        icon: ExternalMinor,
        url: metabaseUrl
      }]}
    >
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
          {(selected === 0) && <Page1 />}
          {(selected === 1) && <Page2 />}
          {(selected === 2) && <Page3 />}
        </Tabs>
      </Card>
      <FooterHelp>
        <Link url='/privacy'>
          隱私權聲明
        </Link>
      </FooterHelp>
    </Page>
  )
}
