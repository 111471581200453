import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
  const isLogin = localStorage.getItem('accessToken')

  if (isLogin) return children
  return <Navigate to='/login' />
}

ProtectedRoute.propTypes = {
  children: PropTypes.element
}

export default ProtectedRoute
