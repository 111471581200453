import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Button, IndexTable, Spinner, Text } from '@shopify/polaris'
import { PageDownMajor } from '@shopify/polaris-icons'
import { authInstance } from '../helper'

function Page2 () {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchTasks = () => {
    setLoading(true)
    authInstance.get('/tasks').then(res => {
      setTasks(res.data)
      setLoading(false)
    })
  }

  const handleRefresh = useCallback(() => {
    fetchTasks()
  }, [])

  useEffect(() => {
    fetchTasks()
  }, [])

  const statusType = Object.freeze({
    pending: 'attention',
    processing: 'warning',
    success: 'success',
    fail: 'critical'
  })

  const rowMarkup = tasks.map(
    ({ createdAt, id, title, status, downloadUrl, errorMsg }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{createdAt}</IndexTable.Cell>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {title}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge status={statusType[status]}>
            {status}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {(downloadUrl) &&
            <Button icon={PageDownMajor} url={downloadUrl} color="base" />
          }
          </IndexTable.Cell>
        <IndexTable.Cell>{errorMsg}</IndexTable.Cell>
      </IndexTable.Row>
    )
  )

  return (
    <div className='px-16 py-4'>
      <div className='flex justify-end mb-3'>
        <Button primary onClick={handleRefresh}>重新整理</Button>
      </div>
      {(loading) && (<div className='flex justify-center items-center h-[200px]'>
        <Spinner />
      </div>)}
      {(!loading) && (tasks.length === 0) && (<div className='flex justify-center'>
        <p className='text-lg font-bold'>七天內未建立任何工作項目</p>
      </div>)}
      {(!loading) && (tasks.length > 0) && (
        <div className='bg-gray-100 border-2 border-solid'>
          <IndexTable
            itemCount={tasks.length}
            headings={[
              { title: '建立時間' },
              { title: '工作項目' },
              { title: '狀態' },
              { title: '下載連結' },
              { title: '錯誤訊息' }
            ]}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
        </div>
      )}
    </div>
  )
}

export default Page2
