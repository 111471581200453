import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import CallbackPage from './pages/CallbackPage'
import AdminLoginPage from './pages/admin/AdminLoginPage'
import AdminPage from './pages/admin/AdminPage'
import ErrorPage from './pages/ErrorPage'
import Main from './pages/Main'
import ProtectedRoute from './components/ProtectedRoute'
import Privacy from './pages/Privacy'

export default function App () {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/callback' element={<CallbackPage />} />
        <Route path='/admin/login' element={<AdminLoginPage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/error' element={<ErrorPage />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/' element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  )
}
