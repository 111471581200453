import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { adminInstance } from '../../helper'
import Logo from '../../assets/orca-logo.png'
import { ArrowRightOnRectangleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { Modal, Form, FormLayout, TextField } from '@shopify/polaris'

const Nav = () => {
  return (
    <div className="bg-blue-100 mb-5 border-b border-gray-200">
      <header className="px-2 flex justify-between items-center">
        {<h2 className="p-2 text-xl font-medium flex items-center text-black hover:text-gray-800 md:text-2xl">
          <img
            src={Logo}
            alt="ORCA"
            className="w-10 h-10 inline-block mr-2"
          />
          Shopify ORCA 管理員後台
        </h2>}
        <nav>
          <ul className="flex items-center gap-4 text-lg text-black">
            <li>
              <Link
                onClick={() => {
                  window.localStorage.removeItem('adminToken')
                  window.location.href = '/admin/login'
                }}
                to="/login"
                replace
                className="bg-stone-300 px-2 py-1 rounded-md flex items-center hover:bg-gray-500 hover:text-white"
              >
                登出
                <ArrowRightOnRectangleIcon className="w-6 h-6" />
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const EditModal = ({ open, onClose, shopId }) => {
  const [shop, setShop] = useState({})

  const fetchShopData = async () => {
    try {
      const res = await adminInstance.get(
        `/shop/${shopId}`
      )
      setShop(res.data.data)
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const updateMetabaseUrl = async () => {
    // eslint-disable-next-line camelcase
    const { id, metabase_url } = shop
    try {
      await adminInstance.patch(
        `/shop/${id}/update-metabase-url`,
        // eslint-disable-next-line camelcase
        { metabase_url }
      )
      onClose()
    } catch (error) {
      console.log(error.response.data)
    }
  }

  useEffect(() => {
    fetchShopData()
  }, [shopId])

  return (
    <>
    {shop && (
    <Modal
      open={open}
      onClose={onClose}
      title='編輯'
      primaryAction={{
        content: '更新',
        onAction: updateMetabaseUrl
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
          <TextField label="商店名稱" value={shop.shopname} disabled />
          <TextField
            type="text"
            label="Metabase URL"
            value={shop.metabase_url}
            onChange={(value) => {
              setShop({ ...shop, metabase_url: value })
            }}
          />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>)}
    </>
  )
}

const AdminPage = () => {
  const [shops, setShops] = useState([])
  const [selectedShop, setSelectedShop] = useState(null)
  const [openEditModal, setOpenModal] = useState(false)

  const showEditModal = (shopId) => {
    setSelectedShop(shopId)
    setOpenModal(true)
  }

  const closeEditModal = () => {
    setOpenModal(false)
  }

  const fetchShops = async () => {
    try {
      const res = await adminInstance.get('/shops')
      setShops(res.data.data)
    } catch (error) {
      console.log(error.response.data)
    }
  }

  useEffect(() => {
    fetchShops()
  }, [openEditModal])

  return (
    <div>
        <Nav />
        <section className="overflow-x-auto lg:flex lg:justify-center">
          <table className="table-fixed md:text-lg rounded-md overflow-hidden whitespace-nowrap shadow-lg mb-10">
            <thead className="bg-gray-800 text-gray-50">
              <tr>
                <th className="px-3 py-2">ID</th>
                <th className="px-3 py-2">商店名稱</th>
                <th className="px-3 py-2">Metabase URL</th>
                <th className="px-3 py-2">操作</th>
              </tr>
            </thead>
            <tbody>
              {shops.length === 0
                ? (
                  <tr>
                    <td colSpan="6" className="text-center py-5 px-56 bg-slate-100">
                    沒有安裝此應用程式的商店
                    </td>
                  </tr>
                  )
                : (shops.map((shop) => (
                  <tr
                    key={shop.id}
                    className="group even:bg-blue-200 even:hover:bg-sky-900 even:hover:text-white"
                  >
                    <td className="px-3 py-2 text-center">{shop.id}</td>
                    <td className="px-3 py-2">{shop.shopname}</td>
                    <td className="px-3 py-2">{shop.metabase_url}</td>
                    <td className="px-3 py-2">
                    <button
                      type="button"
                      className="flex items-center"
                      onClick={() => showEditModal(shop.id)}
                    >
                      <PencilSquareIcon className="w-8 h-8 text-green-500" />
                    </button>
                    </td>
                  </tr>
                  ))
                  )
              }
            </tbody>
          </table>
        </section>
        {selectedShop && <EditModal
          open={openEditModal}
          onClose={closeEditModal}
          shopId={selectedShop}
        />}
      </div>
  )
}

export default AdminPage
