import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, DropZone, Stack, Thumbnail, Text } from '@shopify/polaris'
import { NoteMinor } from '@shopify/polaris-icons'

function DropZoneComponent ({ title, file, setFile }) {
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  )

  const validFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

  const fileUpload = !file && <DropZone.FileUpload actionTitle='上傳檔案' actionHint="Accepts .xlsx"/>
  const uploadedFile = file && (
    <Stack>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validFileTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteMinor
        }
      />
      <div>
        {file.name}{' '}
        <Text variant="bodySm" as="p">
          {file.size} bytes
        </Text>
      </div>
    </Stack>
  )

  return (
    <Card title={title} sectioned>
      <DropZone allowMultiple={false} onDrop={handleDropZoneDrop} variableHeight>
        {uploadedFile}
        {fileUpload}
      </DropZone>
    </Card>
  )
}

DropZoneComponent.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.any.isRequired,
  setFile: PropTypes.func.isRequired
}

export default DropZoneComponent
