import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { instance } from '../helper'

const CallbackPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const queryString = window.location.search
    instance({
      method: 'GET',
      url: `/shopify/callback${queryString}`
    })
      .then((res) => {
        window.localStorage.setItem('accessToken', res.data.access_token)
        window.location.href = '/'
      })
      .catch(() => navigate('/error'))
  }, [])

  return (
    <div>
      <h2>認證中，請稍待片刻......</h2>
    </div>
  )
}

export default CallbackPage
