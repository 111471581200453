import React, { useEffect, useState } from 'react'
import { Card, Form, TextField } from '@shopify/polaris'
import { BACKEND_URL } from '../helper'
import Logo from '../assets/orca-logo.png'

const LoginPage = () => {
  const [shop, setShop] = useState('')

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const shopname = urlParams.get('shop')
    if (shopname) setShop(shopname)
  }, [])

  const handleChange = (value) => setShop(value)

  const login = () => {
    const redirect = 'https://bbc.shopify-orca.punwave.com/callback'
    window.location.replace(`${BACKEND_URL}/shopify/login?shop=${shop}&redirect=${redirect}`)
  }

  return (
    <section className="h-screen flex items-center justify-center bg-slate-500">
      <div className='w-1/2'>
        <Card
          sectioned
          primaryFooterAction={{ content: '登入', onClick: () => login() }}
        >
          <Card.Section>
            <div className='flex justify-center items-center'>
              <img
                src={Logo}
                className="w-[300px]"
              />
            </div>
            <Form>
              <TextField
                value={shop}
                onChange={handleChange}
                label="商店"
                placeholder="Example: xxxxx.myshopify.com"
                autoComplete="off"
              />
            </Form>
          </Card.Section>
        </Card>
      </div>
    </section>
  )
}

export default LoginPage
