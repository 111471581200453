import axios from 'axios'

// const BACKEND_URL = 'http://localhost:3000/api'
const BACKEND_URL = 'https://bbc.shopify-orca.punwave.com/api'

const instance = axios.create({
  baseURL: BACKEND_URL
})

const authInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
  }
})

const adminInstance = axios.create({
  baseURL: `${BACKEND_URL}/admin`,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('adminToken')}`
  }
})

authInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 422) {
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

adminInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 422) {
    window.location.href = '/admin/login'
  }
  return Promise.reject(error)
})

export { BACKEND_URL, instance, authInstance, adminInstance }
