import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, FormLayout, Checkbox } from '@shopify/polaris'
import { authInstance } from '../helper'

const ExportModal = (props) => {
  const { active, handleChange, setOpenSuccessBanner } = props
  const [crmProfile, setCrmProfile] = useState(true)
  const [item, setItem] = useState(true)
  const [order, setOrder] = useState(false)

  const handleCrmProfileChange = useCallback(
    (value) => setCrmProfile(value),
    []
  )

  const handleItemChange = useCallback(
    (value) => setItem(value),
    []
  )

  const handleOrderChange = useCallback(
    (value) => setOrder(value),
    []
  )

  const exportFiles = async () => {
    const obj = {
      crmProfile,
      item,
      order
    }
    await authInstance.get('/reports/download', {
      params: {
        types: Object.keys(obj).filter(key => obj[key]).join(',')
      }
    })
    setOpenSuccessBanner(true)
    handleChange()
  }

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title="選擇要匯出的報表內容"
      primaryAction={{
        content: '匯出',
        onAction: exportFiles
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
          <Checkbox
            label="CRM Profile"
            checked={crmProfile}
            onChange={handleCrmProfileChange}
          />
          <Checkbox
            label="商品資料"
            checked={item}
            onChange={handleItemChange}
          />
          <Checkbox
            label="訂單資料"
            checked={order}
            onChange={handleOrderChange}
          />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

ExportModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setOpenSuccessBanner: PropTypes.func.isRequired
}

export default ExportModal
