import React, { useState, useCallback, useEffect } from 'react'
import { Banner, Button, Card, Form, FormLayout, TextField } from '@shopify/polaris'
import { authInstance } from '../helper'

const Page3 = () => {
  const [token, setToken] = useState('')
  const [channelId, setChannelId] = useState('')
  const [channelSecret, setChannelSecret] = useState('')

  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [openWarningBanner, setOpenWarningBanner] = useState(true)
  const [openErrorBanner, setOpenErrorBanner] = useState(false)

  const getProfile = async () => {
    const response = await authInstance.get('/me', {
      params: {
        fields: 'channelId,channelSecret,multipassToken'
      }
    })

    const { channelId, channelSecret, multipassToken } = response.data
    if (channelId) setChannelId(channelId)
    if (channelSecret) setChannelSecret(channelSecret)
    if (multipassToken) setToken(multipassToken)
  }

  useEffect(() => {
    getProfile()
  }, [])

  const handleTokenChange = useCallback(
    (value) => setToken(value),
    []
  )
  const handleChannelIdChange = useCallback(
    (value) => setChannelId(value),
    []
  )
  const handleChannelSecretChange = useCallback(
    (value) => setChannelSecret(value),
    []
  )

  const handleSubmit = async () => {
    try {
      await authInstance.post('/me', {
        multipassToken: (token === '') ? null : token,
        channelId: (channelId === '') ? null : channelId,
        channelSecret: (channelSecret === '') ? null : channelSecret
      })
      setOpenSuccessBanner(true)
      setOpenWarningBanner(false)
    } catch (error) {
      setOpenErrorBanner(true)
      setOpenWarningBanner(false)
    }
  }

  return (
    <section className='px-16 py-8 flex justify-center items-center bg-gray-100'>
      <div className='w-3/4'>
        <Card>
          <Card.Section>
            {openSuccessBanner && <Banner
              title='更新成功'
              status='success'
              onDismiss={() => {
                setOpenSuccessBanner(false)
                setOpenWarningBanner(true)
              }}
            />}
            {openErrorBanner && <Banner
              title='更新失敗'
              status='critical'
              onDismiss={() => {
                setOpenErrorBanner(false)
                setOpenWarningBanner(true)
              }}/>}
            {openWarningBanner && <Banner
              title="只有 Shopify Plus 的用戶可以使用 Multipass 功能"
              status="warning"
            />}
          </Card.Section>
          <Card.Section>
            <Form onSubmit={handleSubmit}>
              <FormLayout>
                <TextField
                  value={channelId}
                  onChange={handleChannelIdChange}
                  label="LINE Channel ID"
                  type="string"
                  autoComplete="off"
                />
                <TextField
                  value={channelSecret}
                  onChange={handleChannelSecretChange}
                  label="LINE Channel Secret"
                  type="string"
                  autoComplete="off"
                />
                <TextField
                  value={token}
                  onChange={handleTokenChange}
                  label="Multipass Token"
                  type="string"
                  autoComplete="off"
                />
                <div className='flex justify-end'>
                  <Button submit primary>更新</Button>
                </div>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      </div>
    </section>
  )
}

export default Page3
